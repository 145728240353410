import React from "react";
import Navbar from "../components/Navbar";
import { Carousel } from '@trendyol-js/react-carousel';
import FocusDImg from "../assets/img/focus-d.png";
import FocusCImg from "../assets/img/focus-c.png";
import FocusAImg from "../assets/img/focus-a.png";
import RoundTextYellow from "../assets/img/playreel.png";
import IrregularImg from "../assets/img/irregular.png";
import ZigzagImg from "../assets/img/zigzag.png";
import ViewCaseW from "../assets/img/view-casew.svg";

import Service2Img from "../assets/img/service-2.png";
import QuoteImg from "../assets/img/quote.svg";

import Contact from "../components/Contact";
import Footer from "../components/Footer";

function Services() {

  return (
    <div>
      <section>
        <div className="services-hero-bg">
          <Navbar activeLink="services" />

          <div className="container">
            <h1>Get all the <span>benefits<br />of</span> working with<br /><span>full-cycle agency</span></h1>

            <p>Learn more about the team of professionals who care<br />about your product as much as you do and the fields<br />that we can help you with.</p>
          </div>
        </div>
      </section>

      <section>
        <div className="services-carousel">
          <Carousel show={2.5} slide={1} swiping={true}>
            <img src={FocusDImg} style={{ width: '90%', height: 300 }} alt="" />
            <img src={FocusCImg} style={{ width: '90%', height: 300 }} alt="" />
            <img src={FocusAImg} style={{ width: '90%', height: 300 }} alt="" />
          </Carousel>
        </div>
      </section>

      <section>
        <div className="professionalism-div">
          <div className="container">
            <h1>Is it <span>possible</span> to<br /> blend technology,<br />professionalism,<br />and aesthetics?</h1>

            <div>
              <a href="#portfolio" 
                className="professionalism-btn">
                <img src={RoundTextYellow} alt="Rounded Text"/>
                <i className="las la-play"></i>
              </a>

              <div className="row">
                <div className="col-12 col-md-4 mb-5">
                  <div className="professionalism-blocks">
                    <div className="professionalism-img-div mb-4" />
                    
                    <p>Modifications to the design are necessary because it cannot be implemented because it did not adhere to technical specifications on the development side.</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-5">
                  <div className="professionalism-blocks">
                    <div className="professionalism-img-div mb-4" />
                    
                    <p>Wasted time due to inconsistencies in both processes and those workingon the project.</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-5">
                  <div className="professionalism-blocks">
                    <div className="professionalism-img-div mb-4" />
                    
                    <p>Combining modern approaches, sleek design, and adaptable processes is too difficult</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-5">
                  <div className="professionalism-blocks">
                    <div className="professionalism-img-div mb-4" />
                    
                    <p>Our team is made up of specialists from a range of fields. Their compreh ensive approach takes care of everything from research and business analysis to design and development.</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-5">
                  <div className="professionalism-blocks">
                    <div className="professionalism-img-div mb-4" />
                    
                    <p>No business has ever succeeded with poor design solutions. To us, design is the nucleus that holds business together. We’re always willing to help you build it.</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-5">
                  <div className="professionalism-blocks">
                    <div className="professionalism-img-div mb-4" />
                    
                    <p>Creative design attracts people. Smart UX makes them stay. At StudioTheon we deliver both. For you, for people, for business.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="design-services-div">
          <div className="container">
            <div className="row" style={{ borderBottom: '1px solid #dcc7b3', paddingBottom: '1rem' }}>
              <div className="col-md-12">
                <h1>Design Services</h1>
              </div>
            </div>

            <div className="row design-services-port">
              <div className="col-md-1">
                <span className="design-port-number">01</span> 
              </div>
              <div className="col-md-5">
                <span className="design-port-title">Brand<br />Innovation</span>
              </div>
              <div className="col-md-6">
                <p className="design-port-text">Your brand identity is your way of standing out from the crowd and catching the attention of your target market</p>

                <p className="design-port-timeline">From 1 week</p>

                <div style={{ paddingLeft: '2rem' }}>
                  <hr />
                </div>

                <span className="design-port-services">Brand Identity &nbsp;&nbsp;Branding kit &nbsp;&nbsp;Rebranding &nbsp;&nbsp;Logotype</span>
              </div>
            </div>

            <div className="row design-services-port">
              <div className="col-md-1">
                <span className="design-port-number">01</span> 
              </div>
              <div className="col-md-5">
                <span className="design-port-title">Web & Mobile<br />Application Design</span>
              </div>
              <div className="col-md-6">
                <p className="design-port-text">We take a thorough approach to creating products for our clients, and the end result is a solution that will develop your business and meet the needs of your users</p>

                <p className="design-port-timeline">From 1 week</p>

                <div style={{ paddingLeft: '2rem' }}>
                  <hr />
                </div>

                <span className="design-port-services">Websites &nbsp;&nbsp;Web applications</span>
              </div>
            </div>

            <div className="row design-services-port">
              <div className="col-md-1">
                <span className="design-port-number">01</span> 
              </div>
              <div className="col-md-5">
                <span className="design-port-title">Website<br />Design</span>
              </div>
              <div className="col-md-6">
                <p className="design-port-text">We create eye-catching and highly optimized websites that will convey your business's story and values to your target audience, and also provide an avenue for marketing</p>

                <p className="design-port-timeline">From 1 week</p>

                <div style={{ paddingLeft: '2rem' }}>
                  <hr />
                </div>

                <span className="design-port-services">Promo Landing Pages &nbsp;&nbsp;Websites &nbsp;&nbsp;Redesign</span>
              </div>
            </div>

            <div className="row design-services-port">
              <div className="col-md-1">
                <span className="design-port-number">01</span> 
              </div>
              <div className="col-md-5">
                <span className="design-port-title">Motion Graphics &<br />Illustration</span>
              </div>
              <div className="col-md-6">
                <p className="design-port-text">We produce videos and illustrations that help businesses demonstrate their messages and values in an engaging way. They capture the audience's interest, and increase loyalty and brand recognition.</p>

                <p className="design-port-timeline">From 1 week</p>

                <div style={{ paddingLeft: '2rem' }}>
                  <hr />
                </div>

                <span className="design-port-services">Motion graphics &nbsp;&nbsp;2D/3D Illustrations</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="discovery-div">
          <div className="container">
            <div className="row">
              <div className="col-md-6 ">
                <span>Analysis Service</span>

                <h1 className="mb-4">Discovery<br />Phase</h1>

                <p className="discovery-paragraph">This step helps us turn great ideas into successful products, as it provides us with insights into your needs and how we can cater to them.</p>

                <p className="discovery-paragraph">So, the discovery stage is crucial for organizing the development process effectively, and linking your business goals with your users’ demands.</p>

                <p className="discovery-paragraph">It also helps reduce costs on design and development, as it reduces the number of iterations that are needed.</p>

                <p className="discovery-paragraph-yellow">From 1 week</p>
              </div>
              <div className="col-md-6 text-center">
                <img src={IrregularImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="cooperate-div">
          <div className="container">
            <h1>Our developers and<br /> designers <span>cooperate<br />closely</span> to overcome<br />any challenges</h1>

            <div>
              <div className="row">
                <div className="col-12 col-md-4 mb-5">
                  <div className="cooperate-blocks">
                    <div className="cooperate-img-div mb-4" />
                    
                    <p>Modifications to the design are necessary because it cannot be implemented because it did not adhere to technical specifications on the development side.</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-5">
                  <div className="cooperate-blocks">
                    <div className="cooperate-img-div mb-4" />
                    
                    <p>Wasted time due to inconsistencies in both processes and those workingon the project.</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-5">
                  <div className="cooperate-blocks">
                    <div className="cooperate-img-div mb-4" />
                    
                    <p>Combining modern approaches, sleek design, and adaptable processes is too difficult</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="development-services-div">
          <div className="container">
            <div className="row" style={{ borderBottom: '1px solid #0b1d18', paddingBottom: '1rem' }}>
              <div className="col-md-12">
                <h1>Development<br />Services</h1>
              </div>
            </div>

            <div className="row development-services-port">
              <div className="col-md-1">
                <span className="development-port-number">01</span> 
              </div>
              <div className="col-md-5">
                <span className="development-port-title">Web<br />Development</span>
              </div>
              <div className="col-md-6">
                <p className="development-port-text">With the help of custom code, we can create products of any complexity. Additionally, thanks to our expertise, we find unique custom designs and offer simpler but equally effective solutions.</p>

                <p className="development-port-timeline">From 1 week</p>

                <div style={{ paddingLeft: '2rem' }}>
                  <hr />
                </div>

                <span className="development-port-services">Websites &nbsp;&nbsp;Web applications</span>
              </div>
            </div>

            <div className="row development-services-port">
              <div className="col-md-1">
                <span className="development-port-number">01</span> 
              </div>
              <div className="col-md-5">
                <span className="development-port-title">No-code<br />solutions</span>
              </div>
              <div className="col-md-6">
                <p className="development-port-text">No-code development is perfect for producing websites and products quickly, while minimizing the amount of resources that are required to do so. It's a good choice for MVP versions and small promotional sites.</p>

                <p className="development-port-timeline">From 1 week</p>

                <div style={{ paddingLeft: '2rem' }}>
                  <hr />
                </div>

                <span className="development-port-services">Webflow</span>
              </div>
            </div>

            <div className="row development-services-port">
              <div className="col-md-1">
                <span className="development-port-number">01</span> 
              </div>
              <div className="col-md-5">
                <span className="development-port-title">Mobile<br />Development</span>
              </div>
              <div className="col-md-6">
                <p className="development-port-text">We help clients unlock the potential of mobile development, focusing on the core needs of product users and implementing custom solutions of any complexity.</p>

                <p className="development-port-timeline">From 1 week</p>

                <div style={{ paddingLeft: '2rem' }}>
                  <hr />
                </div>

                <span className="development-port-services">Native iOS & Android &nbsp;&nbsp;Cross-platform</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="tailor-div">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-7">
                <h1 className="mb-4">We tailor our<br />approach to your<br />needs</h1>

                <p className="tailor-paragraph">With us, you get the benefits of working with a full-cycle studio, or services adapted to your project. We’re ready to put our expertise to use. We can work directly end-to-end with your company, but you can also take on one of our specialists on a long-term basis. With this approach, you can assemble a team that is fully dedicated to your project.</p>
              </div>
              <div className="col-md-5 text-center">
                <img src={ZigzagImg} alt="" />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 mb-5">
                <div className="tailor-blocks">
                  <div className="tailor-img-div mb-3" />

                  <h6 className="mb-3">End-to-end</h6>
                  
                  <p className="mb-5">We manage every aspect of the project that is entrusted to our team, and provide you with detailed reports about what we’re doing — along with updates from the project manager.</p>

                  <button><img src={ViewCaseW} alt="" /> Get in Touch</button>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-5">
                <div className="tailor-blocks">
                  <div className="tailor-img-div mb-3" />

                  <h6 className="mb-3">Dedicated team</h6>
                  
                  <p className="mb-5">Searching for specialists on your own is a time- consuming task. That’s why we can provide you with a 100% independent professional who will fit in with your team and help get your project over the line as quickly as possible.</p>

                  <button><img src={ViewCaseW} alt="" />  Get in Touch</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="idea-div">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <span>Have an idea?</span>

                <h1 className="mb-5">Can’t decide on the<br />service? <span>Talk with us</span></h1>

                <p className="idea-paragraph mb-5">Our studio never sets itself the goal of offering a client a service that will be superfluous for business. Our main goal and mission among customers.</p>

                <p className="idea-paragraph">Also, we can always help with the choice of technology stack for your project.</p>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="cases-div">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <span className="mb-4">Our Cases</span>

                <h1 className="mb-4">Some cases<br />we’re proud of</h1>

                <p className="cases-paragraph mb-4">Our studio never sets itself the goal of offering a client a service that will be superfluous for business. Our main goal and mission among customers.</p>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="our-service">
          <img src={Service2Img} alt="" />
        </div>
      </section>

      <section>
        <div className="reviews-bg">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-12">
                <h3>Reviews</h3>
                <h1>What do people say<br />about us?</h1>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-md-6 mb-5">
                <div className="review-blocks">
                  <div className="review-img-div mb-4">
                    <img src={QuoteImg} alt="" />
                  </div>
                  
                  <p className="mb-5">Go through our works to see the examples of our approach and challenges that we solve for our customers.</p>

                  <div className="row review-user">
                    <div className="col-3">
                      <span></span>
                    </div>
                    <div className="col-9">
                      <p>John Doe</p>
                      <p>CTO GAYO Technologies</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="review-blocks">
                  <div className="review-img-div mb-4">
                    <img src={QuoteImg} alt="" />
                  </div>
                  
                  <p className="mb-5">Go through our works to see the examples of our approach and challenges that we solve for our customers.</p>

                  <div className="row review-user">
                    <div className="col-3">
                      <span></span>
                    </div>
                    <div className="col-9">
                      <p>John Doe</p>
                      <p>CTO GAYO Technologies</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Contact />
      </section>

      <section>
        <Footer />
      </section>
    </div>
  );
}

export default Services;
