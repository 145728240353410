import React from "react";
import Navbar from "../components/Navbar";

import About1Img from "../assets/img/about-1.png";
import FocusBImg from "../assets/img/focus-b.png";
import ViewCase from "../assets/img/view-case.svg";
import ViewCaseG from "../assets/img/view-caseg.svg";

import Contact from "../components/Contact";
import Footer from "../components/Footer";

function About() {

  return (
    <div>
      <section>
        <div className="about-hero-bg">
          <Navbar activeLink="about" />

          <div className="container">
            <h1>There’s a better<br />way to work</h1>

            <p>Products that shift paradigms blend creativity, consistency,<br />and attention to detail. The Theon team’s range of talents<br />and abilities means that we find unique solutions to<br />every challenge</p>
          </div>
        </div>
      </section>

      <section>
        <div className="mission-div">
          <div className="container">
            <div>
              <div className="row">
                <div className="col-12 col-md-4 mb-5">
                  <div className="mission-blocks">
                    <div className="mission-img-div mb-4" />

                    <h6 className="mb-3">Mission</h6>
                    
                    <p>We create innovative digital products and solutions for our clients so they can improve customer engagement.</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-5">
                  <div className="mission-blocks">
                    <div className="mission-img-div mb-4" />

                    <h6 className="mb-3">Principle</h6>
                    
                    <p>We work with startups so they can meet the challenges that today’s ever-changing business landscape presents</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-5">
                  <div className="mission-blocks">
                    <div className="mission-img-div mb-4" />

                    <h6 className="mb-3">Our Team</h6>
                    
                    <p>We inspire each other to enhance our clients’ products and reach new heights.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="about-portfolio-bg">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-md-6">
                        <div className="about-desc-div">
                        <h1 className="mb-4">We build<br />outstanding<br />brands.</h1>

                        <p className="about-desc-paragraph mb-4">We craft outstanding direct-to-consumer brands with the common ambition to offer true craftsmanship, timeless products that are carbon neutral – and this without ever making any concessions.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="focus-div">
                        <img src={About1Img} className="main-img mb-3" alt="" />

                        <h4 className="mb-3">Brand Strategy</h4>
                        <button><img src={ViewCase} alt="" /> View case</button>
                        </div>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-6">
                        <div className="about-desc-div">
                            <h1 className="mb-4">Projects</h1>

                            <p className="about-desc-paragraph mb-4">Check out the projects we have<br />completed for our clients</p>
                            <button><img src={ViewCaseG} alt="" /> View our projects</button>
                        </div>

                        <div className="about-desc-div">
                            <h1 className="mb-4">Services</h1>

                            <p className="about-desc-paragraph mb-4">Discover the multitude of methods we<br />can use to enhance your product</p>
                            <button><img src={ViewCaseG} alt="" /> View our services</button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="focus-div">
                        <img src={FocusBImg} className="main-img mb-3" alt="" />

                        <h4 className="mb-3">Commerce</h4>
                        <button><img src={ViewCase} alt="" /> View case</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
      </section>

      <section>
        <Contact />
      </section>

      <section>
        <Footer />
      </section>
    </div>
  );
}

export default About;
