import React from "react";
import Navbar from "../components/Navbar";

import FocusAImg from "../assets/img/focus-a.png";
import FocusBImg from "../assets/img/focus-b.png";
import FocusCImg from "../assets/img/focus-c.png";
import FocusDImg from "../assets/img/focus-d.png";
import ViewCase from "../assets/img/view-case.svg";

import Projects2Img from "../assets/img/projects-2.png";

import Contact from "../components/Contact";
import Footer from "../components/Footer";

function Projects() {

  return (
    <div>
      <section>
        <div className="projects-hero-bg">
          <Navbar activeLink="projects" />

          <div className="container">
            <h1>Explore our <span>projects</span></h1>

            <div className="row item-bottom-border mb-3">
                <div className="col-md-3">
                    <p className="active-top-para">All Projects</p>
                </div>
                <div className="col-md-2">
                    <p className="top-para">Web App</p>
                </div>
                <div className="col-md-2">
                    <p className="top-para">Website</p>
                </div>
                <div className="col-md-2">
                    <p className="top-para">Branding</p>
                </div>
                <div className="col-md-3">
                    <p className="top-para">3D Motion Design</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-3">
                    <p className="active-bottom-para">All Projects</p>
                </div>
                <div className="col-md-2">
                    <p className="bottom-para">Fintech</p>
                </div>
                <div className="col-md-2">
                    <p className="bottom-para">Education</p>
                </div>
                <div className="col-md-2">
                    <p className="bottom-para">Healthcare</p>
                </div>
                <div className="col-md-3">
                    <p className="bottom-para">Traveling & Booking</p>
                </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="project-portfolio-bg">
            <div className="container">
                <div className="row mb-5">
                <div className="col-md-6">
                    <div className="focus-div">
                    <img src={FocusAImg} className="main-img mb-3" alt="" />

                    <h4 className="mb-3">Brand Strategy</h4>
                    <button className="mb-5"><img src={ViewCase} alt="" /> View case</button>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="focus-div">
                    <img src={FocusBImg} className="main-img mb-3" alt="" />

                    <h4 className="mb-3">Commerce</h4>
                    <button><img src={ViewCase} alt="" /> View case</button>
                    </div>
                </div>
                </div>

                <div className="row mb-5">
                <div className="col-md-10">
                    <div className="focus-div">
                    <img src={FocusCImg} className="main-img mb-3" alt="" />

                    <h4 className="mb-3">B2B & B2C Website</h4>
                    <button><img src={ViewCase} alt="" /> View case</button>
                    </div>
                </div>
                <div className="col-md-2"></div>
                </div>

                <div className="row mb-5">
                <div className="col-md-2"></div>
                <div className="col-md-10">
                    <div className="focus-div">
                    <img src={FocusDImg} className="main-img mb-3" alt="" />

                    <h4 className="mb-3">Marketing & Media</h4>
                    <button><img src={ViewCase} alt="" /> View case</button>
                    </div>
                </div>
                </div>

                <div className="text-center pt-5">
                    <a href="#" className="all-projects">
                    <i className="las la-arrow-right"></i> All Projects
                    </a>
                </div>
            </div>
        </div>
      </section>

      <section>
        <div className="our-projects">
            <img src={Projects2Img} alt="" />
        </div>
      </section>

      <section>
        <Contact />
      </section>

      <section>
        <Footer />
      </section>
    </div>
  );
}

export default Projects;
