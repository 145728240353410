import React, { useEffect, useRef } from 'react';

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    videoElement.play(); // Start playing the video

    /* return () => {
      videoElement.pause(); // Pause the video on component unmount
    }; */
  }, []);

  return (
    <video id="video" ref={videoRef} src={src} autoPlay muted className="loader-vid" />
  );
};

export default VideoPlayer;